import {
    ApiReactiveService,
    type ApiServiceUseFetchOptions
} from '@composable-api/service/api.reactive-service'
import type { ApiModel, ApiModelAttributes } from '@composable-api/api.model'
import { CartShippingMethodModel } from '../models/cart-shipping-method.model'
import { type MaybeRefOrGetter, toValue } from 'vue'
import type { ApiServiceFetchOptions } from '@composable-api/service/api.service'

class CartShippingMethodsApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }

    get(options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'GET',
        })
    }

    post(data: Partial<ApiModelAttributes<M>>, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'POST',
            body: data,
        })
    }

    put(data: Partial<ApiModelAttributes<M>>, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'PUT',
            body: data,
        })
    }
}

export default function useCartShippingMethodsApiService(options: {
    cartId: MaybeRefOrGetter<string | null | undefined>
}) {
    return new CartShippingMethodsApiReactiveService(
        {
            endpoint: () => `/carts/${toValue(options.cartId)}/shipping-methods`,
        },
        CartShippingMethodModel
    )
}
