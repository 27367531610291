export enum ShippingServiceType {
    NONE = 0,
    STOCK = 1,
    PACKETA = 2,
    CZECH_POST = 3,
    PPL = 4,
}

export enum ShippingMethodAddressRequirement {
    ONLY_BILLING_FULL = 0,
    ONLY_BILLING_BASIC = 1,
    BOTH = 2,
}
