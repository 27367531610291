import { FetchError } from 'ofetch'

/**
 * A helper function to create a lazy-initialized object.
 * This can be useful when you want to create expensive resources
 * only when the subject requiring them is actually accessed.
 *
 * (For example, creating a watcher only when a property from a composable
 * is accessed.)
 * @param dest the destination object, the access to which will trigger the initialization (synchronously before the first access)
 * @param initializer the function that will be called before the first access to the destination object
 */
export function lazyInit<T extends object>(dest: T, initializer: () => any) {
    let hasBeenAccessed = false

    return new Proxy(dest, {
        get(target, prop, receiver) {
            if (!hasBeenAccessed) {
                initializer()
                hasBeenAccessed = true
            }

            return Reflect.get(target, prop, receiver)
        },
    })
}

export function isAbortError(error: any) {
    if (error instanceof FetchError) {
        return error.cause instanceof DOMException && error.cause.name === 'AbortError'
    }
    return error instanceof DOMException && error.name === 'AbortError'
}
