import payload_plugin_BFYSBo3n3r from "/opt/simploshop/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.34.4/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import model_serialization_mFxH9Vcc2b from "/opt/simploshop/shared/modules/simploshop-api/src/runtime/plugins/model-serialization.ts";
import response_serialization_9Nb96RIzeZ from "/opt/simploshop/shared/modules/simploshop-api/src/runtime/plugins/response-serialization.ts";
import api_response_error_serialization_AEPGpW8H3k from "/opt/simploshop/shared/modules/composable-api/src/runtime/plugins/api-response-error-serialization.ts";
import revive_payload_client_DczmGxJCWj from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_x5zfomuifdeortxhoem6l4pvce/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_vuVPVgLV6h from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_x5zfomuifdeortxhoem6l4pvce/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bVtm2Picyi from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_x5zfomuifdeortxhoem6l4pvce/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_hwMop36OI3 from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_x5zfomuifdeortxhoem6l4pvce/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_kD25cfVgCv from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_x5zfomuifdeortxhoem6l4pvce/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NnAAuvMPo7 from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_x5zfomuifdeortxhoem6l4pvce/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_nNajG1WF4b from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_x5zfomuifdeortxhoem6l4pvce/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_bss7EXmaA6 from "/opt/simploshop/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.34.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "/opt/simploshop/themes/demo/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_4yLMCTJVwB from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_x5zfomuifdeortxhoem6l4pvce/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_1NrOLdJoie from "/opt/simploshop/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_4u6qypqhbghhdv3xkpihmwff7m/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_fdCeMA9FHm from "/opt/simploshop/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_4u6qypqhbghhdv3xkpihmwff7m/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_JCIgAg3BnU from "/opt/simploshop/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_4u6qypqhbghhdv3xkpihmwff7m/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import lite_youtube_client_kDCiZBs6WY from "/opt/simploshop/shared/core-simploshop/app/plugins/lite-youtube.client.ts";
import v_visibility_N214iPkHWb from "/opt/simploshop/shared/core-simploshop/app/plugins/v-visibility.ts";
import sentry_client_oJ0WmhsZd2 from "/opt/simploshop/shared/core-theme/app/plugins/prod/sentry.client.ts";
import auth_client_jxSWoRTMlq from "/opt/simploshop/shared/core-theme/app/plugins/auth.client.ts";
import cart_client_OfZaMaRZWE from "/opt/simploshop/shared/core-theme/app/plugins/cart.client.ts";
import global_events_client_f10UUQk6Lm from "/opt/simploshop/shared/core-theme/app/plugins/global-events.client.ts";
import properties_sGCK2d0mhj from "/opt/simploshop/shared/core-theme/app/plugins/properties.ts";
import routing_0TVo85qKvv from "/opt/simploshop/shared/core-theme/app/plugins/routing.ts";
import setup_zrAIVq6G18 from "/opt/simploshop/shared/core-theme/app/plugins/setup.ts";
import ssg_detect_xPQwhiIQtJ from "/opt/simploshop/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_4u6qypqhbghhdv3xkpihmwff7m/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_BFYSBo3n3r,
  model_serialization_mFxH9Vcc2b,
  response_serialization_9Nb96RIzeZ,
  api_response_error_serialization_AEPGpW8H3k,
  revive_payload_client_DczmGxJCWj,
  unhead_vuVPVgLV6h,
  router_bVtm2Picyi,
  payload_client_hwMop36OI3,
  navigation_repaint_client_kD25cfVgCv,
  check_outdated_build_client_NnAAuvMPo7,
  chunk_reload_client_nNajG1WF4b,
  plugin_vue3_bss7EXmaA6,
  components_plugin_zlvi6dcIsi,
  prefetch_client_4yLMCTJVwB,
  switch_locale_path_ssr_1NrOLdJoie,
  route_locale_detect_fdCeMA9FHm,
  i18n_JCIgAg3BnU,
  lite_youtube_client_kDCiZBs6WY,
  v_visibility_N214iPkHWb,
  sentry_client_oJ0WmhsZd2,
  auth_client_jxSWoRTMlq,
  cart_client_OfZaMaRZWE,
  global_events_client_f10UUQk6Lm,
  properties_sGCK2d0mhj,
  routing_0TVo85qKvv,
  setup_zrAIVq6G18,
  ssg_detect_xPQwhiIQtJ
]