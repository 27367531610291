import {
    ApiReactiveService,
    type ApiServiceUseFetchOptions
} from '@composable-api/service/api.reactive-service'
import { CartModel } from '../models/cart.model'
import type { CartPaymentMethodModel } from '../models/cart-payment-method.model'
import type { CartShippingMethodModel } from '../models/cart-shipping-method.model'
import type { CustomerModel } from '../models/customer.model'
import type { ApiModel, ApiModelAttributes } from '@composable-api/api.model'
import type { ApiServiceFetchOptions } from '@composable-api/service/api.service'
import type { MaybeRefOrGetter } from 'vue'

class CartsApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {

    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }

    get(options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'GET',
        })
    }

    post(options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'POST',
        })
    }

    delete() {
        return this.fetch({
            method: 'DELETE',
        })
    }

    usePatch<T extends boolean>(data: MaybeRefOrGetter<Partial<UpdateCartAttributes>>, options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'PATCH',
            body: data,
        })
    }
}

export function useCartsApiService() {
    return new CartsApiReactiveService(
        {
            endpoint: '/carts',
        },
        CartModel
    )
}

export type UpdateCartAttributes = {
    email: ApiModelAttributes<CartModel>['email']
    payment_method_id: ApiModelAttributes<CartPaymentMethodModel>['id']
    shipping_method_id: ApiModelAttributes<CartShippingMethodModel>['id']
    shipping_option_id: string | null
    /**
     * Can only be set when the cart belongs to an anonymous guest.
     */
    customer_id: ApiModelAttributes<CustomerModel>['id']
    /**
     * Indicates whether the shipping address should be expected.
     * Otherwise, it is not taken into account and the billing address
     * is used as the shipping address even when the shipping address is
     * filled in.
     */
    has_shipping_address: boolean
}
