import { ApiModel } from '@composable-api/api.model'
import { type ApiDateTime } from '../types/general-data'
import { ProductModel } from './product.model'
import { ProductVariationModel } from './product-variation.model'

interface Attributes {
    [ProductPackageItemModel.ATTR_ID]: number
    [ProductPackageItemModel.ATTR_PRODUCT_PACKAGE_ID]: number
    [ProductPackageItemModel.ATTR_PRODUCT_ID]: number
    [ProductPackageItemModel.ATTR_PRODUCT_VARIATION_ID]: number | null
    [ProductPackageItemModel.ATTR_COUNT]: number
    [ProductPackageItemModel.ATTR_POSITION]: number
    [ProductPackageItemModel.ATTR_CREATED_AT]: ApiDateTime
    [ProductPackageItemModel.ATTR_UPDATED_AT]: ApiDateTime
}

interface Embeds {
    [ProductPackageItemModel.EMBED_PRODUCT]: ProductModel
    [ProductPackageItemModel.EMBED_PRODUCT_VARIATION]: ProductVariationModel
}

export class ProductPackageItemModel extends ApiModel<Attributes, Embeds> {
    static key = 'ProductPackageItemModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_PRODUCT_PACKAGE_ID = 'product_package_id'
    static readonly ATTR_PRODUCT_ID = 'product_id'
    static readonly ATTR_PRODUCT_VARIATION_ID = 'product_variation_id'
    static readonly ATTR_COUNT = 'count'
    static readonly ATTR_POSITION = 'position'
    static readonly ATTR_CREATED_AT = 'created_at'
    static readonly ATTR_UPDATED_AT = 'updated_at'

    static readonly EMBED_PRODUCT = 'product'
    static readonly EMBED_PRODUCT_VARIATION = 'product_variation'

    get id() {
        return this._getAttribute(ProductPackageItemModel.ATTR_ID)
    }

    get productPackageId() {
        return this._getAttribute(ProductPackageItemModel.ATTR_PRODUCT_PACKAGE_ID)
    }

    get productId() {
        return this._getAttribute(ProductPackageItemModel.ATTR_PRODUCT_ID)
    }

    get productVariationId() {
        return this._getAttribute(ProductPackageItemModel.ATTR_PRODUCT_VARIATION_ID)
    }

    get count() {
        return this._getAttribute(ProductPackageItemModel.ATTR_COUNT)
    }

    get position() {
        return this._getAttribute(ProductPackageItemModel.ATTR_POSITION)
    }

    get createdAt() {
        return this._getAttribute(ProductPackageItemModel.ATTR_CREATED_AT)
    }

    get updatedAt() {
        return this._getAttribute(ProductPackageItemModel.ATTR_UPDATED_AT)
    }

    get product() {
        return this._getEmbed(ProductPackageItemModel.EMBED_PRODUCT, ProductModel)
    }

    get productVariation() {
        return this._getEmbed(ProductPackageItemModel.EMBED_PRODUCT_VARIATION, ProductVariationModel)
    }
}
