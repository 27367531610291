<script lang="tsx">
import { defineComponentBaseContainerContent } from '@core/app/components/base/container/BaseContainerContent.vue'

type PaddingSizes = 'normal' | 'large'
type ContentWidths = 'narrow'

export default defineComponentBaseContainerContent<PaddingSizes, ContentWidths>({
    props: {
        padding: {
            default: 'normal',
        },
    },
})

</script>

<style lang="scss">
@use "@core-scss/components/BaseContainerContent" as *;

// Content widths
@include define-max-width('normal', 1840px);
@include define-max-width('narrow', 904px);

// Default padding size
@include define-padding-size('normal', 3rem, 1rem, 3rem, 1rem);
@include define-padding-size-after-breakpoint('normal', 'md', 3rem, 4.25rem, 3rem, 4.25rem);

// Large padding size
@include define-padding-size('large', 6rem, 1rem, 6rem, 1rem);
@include define-padding-size-after-breakpoint('large', 'md', 6rem, 4.25rem, 6rem, 4.25rem);

// apply the padding sizes
@include apply-defined-padding-sizes('normal', 'large');

</style>
