import { ApiModel } from '@composable-api/api.model'
import type { FormattedCurrency, TaxPerRate } from '../types/general-data'
import { CartShippingMethodModel } from './cart-shipping-method.model'
import { CartPaymentMethodModel } from './cart-payment-method.model'
import { FormattedCurrencyModel } from '../models/custom/formatted-currency.model'
import { OrderInvoiceModel } from './order-invoice.model'
interface Attributes {
    [OrderModel.ATTR_ID]: number
    [OrderModel.ATTR_CUSTOMER_ID]: number | null    // guest customer has `null` value
    [OrderModel.ATTR_EMAIL]: string
    [OrderModel.ATTR_PRICE]: FormattedCurrency | null
    [OrderModel.ATTR_TAXED_PRICE]: FormattedCurrency | null
    [OrderModel.ATTR_WEIGHT]: Weight | null
    [OrderModel.ATTR_SKU]: string | null
    [OrderModel.ATTR_CODE]: string | null
    [OrderModel.ATTR_EAN]: string | null
    [OrderModel.ATTR_STOCK_STATE]: number | null
    [OrderModel.ATTR_CREATED_AT]: string
}

interface Embeds {
    [OrderModel.EMBED_STATE]?: State | null
    [OrderModel.EMBED_SHIPPING]?: CartShippingMethodModel
    [OrderModel.EMBED_PAYMENT]?: CartPaymentMethodModel
    [OrderModel.EMBED_TAX_PER_RATE]?: TaxPerRate
    [OrderModel.EMBED_INVOICES]: OrderInvoiceModel[]
}

export class OrderModel extends ApiModel<Attributes, Embeds> {
    static key = 'OrderModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_CUSTOMER_ID = 'customer_id'
    static readonly ATTR_EMAIL = 'email'
    static readonly ATTR_PRICE = 'price'
    static readonly ATTR_TAXED_PRICE = 'taxed_price'
    static readonly ATTR_WEIGHT = 'weight'
    static readonly ATTR_SKU = 'sku'
    static readonly ATTR_CODE = 'code'
    static readonly ATTR_EAN = 'ean'
    static readonly ATTR_STOCK_STATE = 'stock_state'
    static readonly ATTR_CREATED_AT = 'created_at'

    static readonly EMBED_STATE = 'state'
    static readonly EMBED_SHIPPING = 'shipping'
    static readonly EMBED_PAYMENT = 'payment'
    static readonly EMBED_TAX_PER_RATE = 'tax_per_rate'
    static readonly EMBED_INVOICES = 'invoices'

    get id() {
        return this._getAttribute(OrderModel.ATTR_ID)
    }

    get customerId() {
        return this._getAttribute(OrderModel.ATTR_CUSTOMER_ID)
    }

    get email() {
        return this._getAttribute(OrderModel.ATTR_EMAIL)
    }

    get price() {
        return this._getAttribute(OrderModel.ATTR_PRICE, FormattedCurrencyModel)
    }

    get taxedPrice() {
        return this._getAttribute(OrderModel.ATTR_TAXED_PRICE, FormattedCurrencyModel)
    }

    get weight() {
        return this._getAttribute(OrderModel.ATTR_WEIGHT)
    }

    get sku() {
        return this._getAttribute(OrderModel.ATTR_SKU)
    }

    get code() {
        return this._getAttribute(OrderModel.ATTR_CODE)
    }

    get ean() {
        return this._getAttribute(OrderModel.ATTR_EAN)
    }

    get stockState() {
        return this._getAttribute(OrderModel.ATTR_STOCK_STATE)
    }

    get createdAt() {
        return this._getAttribute(OrderModel.ATTR_CREATED_AT)
    }

    get state() {
        return this._getEmbed(OrderModel.EMBED_STATE)
    }

    get shipping() {
        return this._getEmbed(OrderModel.EMBED_SHIPPING, CartShippingMethodModel)
    }

    get payment() {
        return this._getEmbed(OrderModel.EMBED_PAYMENT, CartPaymentMethodModel)
    }

    get taxPerRate() {
        return this._getEmbed(OrderModel.EMBED_TAX_PER_RATE)
    }

    get invoices() {
        return this._getEmbed(OrderModel.EMBED_INVOICES, OrderInvoiceModel)
    }

    // ---------------------------------------------------------------------------------------------------------------------

    getTaxRate(): number | null {
        const rates = Object.keys(this.taxPerRate ?? {})
        return rates.length > 0 ? Number(rates[0]) : null
    }

    getLatestInvoice(): OrderInvoiceModel | null {
        return this.invoices?.slice(-1)[0] ?? null
    }
}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================


interface Weight {
    unit: string
    value: number
}

interface State {
    id: number,
    label: string | null,
    color: string | null
    text_color: string | null
    makes_shipped: number
    makes_cancelled: number
    makes_completed: number
    makes_paid: number
    makes_invoice: number
    is_initial: boolean
    email_type: string | null
}
