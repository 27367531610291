import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [OrderInvoiceModel.ATTR_ID]: number
    [OrderInvoiceModel.ATTR_NAME]: string
    [OrderInvoiceModel.ATTR_PARENT_ID]: number | null
}

export class OrderInvoiceModel extends ApiModel<Attributes> {
    static key = 'OrderInvoiceModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_PARENT_ID = 'parent_id'

    get id() {
        return this._getAttribute(OrderInvoiceModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(OrderInvoiceModel.ATTR_NAME)
    }

    get parentId() {
        return this._getAttribute(OrderInvoiceModel.ATTR_PARENT_ID)
    }
}
