import { toValue } from 'vue'
import type { ApiModel } from '../api.model'
import type { ConstructorType } from '../../types/utils'
import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'
import { type ApiRoutePrefixes } from '../../types/module'
import { ApiUrlBuilder } from '../url-builder/api.url-builder'
import { joinURL } from 'ufo'
import { useConfig, usePrivateConfig } from '../../private/config'
import { useStateHeaders } from '../../private/state'
import { ApiResponse, type ApiResponseMeta } from '../api.response'
import { ApiResponseError } from '../api.response-error'
import type { FetchOptions, FetchResponse } from 'ofetch'
import { appendResponseHeader, H3Event, parseCookies } from 'h3'
import { useRequestEvent } from 'nuxt/app'
import type { MaybePromise } from 'rollup'
import { isFetchingFromSubdomain } from '../../composables/url'
import {
    getAuthorizationInterceptor,
    getHeadersFromRequestHeadersInterceptor,
    getRefreshSessionInterceptor
} from '../../composables/config'
import{ errorLog } from '../../utils/logging'

export type ApiServiceFetchOptions<T extends ApiModel> = Partial<ServiceFetchOptions<T>>

type ServiceFetchOptions<T extends ApiModel> = {
    /**
     * A function to call after the response is received.
     * @param response The response of the API call.
     */
    onResponse: (response: ApiResponse<T>) => MaybePromise<void>
    /**
     * Whether to pass cookies from the SSR call to the client.
     * @default false
     */
    forwardCookiesFromSSR?: boolean
    headers: Record<string, string>
    signal?: AbortSignal | null
}

export type ServiceFetchBaseOptions<R extends NitroFetchRequest> = {
    method: NitroFetchOptions<R>['method']
    /**
     * The request body.
     */
    body: NitroFetchOptions<R>['body']
    /**
     * Whether the session should be refreshed if it is expired.
     * (This only works when the session refresh strategy is set to `'onError'`)
     * @default true
     */
    refreshOnExpiredSession?: boolean
}

type ApiServiceOptions = ({
    url: NitroFetchRequest
    endpoint?: never
} | {
    url?: never
    endpoint: string
}) & {
    routePrefix?: keyof ApiRoutePrefixes
    relativeUrl?: boolean
}

export function getOnRequestInterceptor(event: H3Event | undefined, isFetchingFromSubdomain: boolean, headers: Record<string, string>) {
    // TODO: type request context correctly
    return ({
        options: _options,
        request,
    }: any) => {
        _options.headers = _options.headers || {}

        _options.headers = {
            ...getHeadersFromRequestHeadersInterceptor({
                event,
            }),
            ..._options.headers,
            ...headers,
        }

        const authInterceptor = getAuthorizationInterceptor()
        if (authInterceptor) {
            authInterceptor(
                {
                    setRequestCookie: (name, value, options) => {
                        if (!_options.headers) _options.headers = {}
                        const cookies = event ? parseCookies(event) : {}

                        const valueToSet = toValue(value) ?? (options?.fallbackToForwarding ? cookies[name] : undefined)
                        if (!valueToSet) return

                        cookies[name] = valueToSet
                        _options.headers['cookie' as keyof typeof _options.headers] =
                            Object.entries(cookies).map(([key, value]) => `${key}=${value}`).join(';')
                    },
                    setRequestHeader: (name, value) => {
                        if (!_options.headers) _options.headers = {}

                        _options.headers[name as keyof typeof _options.headers] = toValue(value)
                    },
                    getClientCookie: (name) => {
                        if (!import.meta.client) return null
                        return document.cookie.split(';')
                            .find(row => row.trim().startsWith(`${name}=`))
                            ?.split('=')[1] ?? null
                    },
                },
                {
                    event: event,
                    willCookiesBeSentFromClient: isFetchingFromSubdomain,
                }
            )
        }
    }
}

/**
 * Refresh the user session by calling the refresh session interceptor.
 * @param event
 * @param isFetchingFromSubdomain
 * @returns `true` if the session was refreshed successfully, `false` otherwise.
 */
export async function refreshSession(event: H3Event | undefined, isFetchingFromSubdomain: boolean): Promise<boolean> {
    const refreshSessionInterceptor = getRefreshSessionInterceptor()
    if (!refreshSessionInterceptor) {
        if (import.meta.dev) {
            errorLog('[composable api]: Refreshing user session is not implemented. No refresh interceptor found.')
        }
        return false
    }

    try {
        const wasSessionRefreshed = await refreshSessionInterceptor({
            event: event,
            willCookiesBeSentFromClient: isFetchingFromSubdomain,
        })
        return wasSessionRefreshed ?? false
    } catch (e) {
        errorLog('[composable api]: Error while refreshing user session', e)
    }
    return false
}

export function transformResponse<T extends ApiModel>(model: ConstructorType<T> | null, data: any, responseMeta: Partial<ApiResponseMeta> | null = null) {
    return new ApiResponse(data, model, responseMeta)
}

export function transformError(response: FetchResponse<any>) {
    return new ApiResponseError(response)
}

export async function apiFetch<T extends ApiModel>(options: {
    model: ConstructorType<T> | null
    url: string
    usageOptions: (Partial<ApiServiceFetchOptions<T> & ServiceFetchBaseOptions<any>>) | undefined
    event: H3Event | undefined
    isFetchingFromSubdomain: boolean
    headers: Record<string, string>
    params: Record<string, any>
    signal: AbortSignal | null | undefined
}) {
    let responseMeta: ApiResponseMeta | null = null

    let shouldRetry = false

    const executeFetch = (skipRetry: boolean = false) => $fetch(options.url, {
        method: options.usageOptions?.method,
        body: options.usageOptions?.body,
        params: options.params,
        credentials: options.isFetchingFromSubdomain ? 'include' : undefined,
        signal: options.signal,
        onRequest: getOnRequestInterceptor(
            options.event,
            options.isFetchingFromSubdomain,
            options.headers
        ),
        onResponse: ({ response }) => {
            responseMeta = {
                status: response.status,
                headers: response.headers,
                body: response.body,
            }

            // forward cookies to the client
            if (options.usageOptions?.forwardCookiesFromSSR && options.event) {
                const cookies = response.headers.getSetCookie()
                for (const cookie of cookies) {
                    appendResponseHeader(options.event, 'set-cookie', cookie)
                }
            }
        },
        onResponseError: (context) => {
            // retry only if the response is 401, and we haven't retried yet
            shouldRetry = context.response.status === 401 && !skipRetry && options.usageOptions?.refreshOnExpiredSession !== false
            // throw an error if the request should not be retried
            if (!shouldRetry) throw transformError(context.response)
        },
    })

    let response

    try {
        response = await executeFetch()
    } catch(e) {
        // throw an error if the request should not be retired
        if (!shouldRetry) throw e
        // --------------------------

        // refresh the session
        const wasSessionRefreshed = await refreshSession(
            options.event,
            options.isFetchingFromSubdomain
        )

        if (!wasSessionRefreshed) {
            // The session was not refreshed, so we should not retry the request
            return
        }

        // retry the request
        response = await executeFetch(true)
    }

    return transformResponse(options.model, response, responseMeta)
}

export class ApiService<T extends ApiModel> extends ApiUrlBuilder<T> {
    private readonly model: ConstructorType<T> | null = null
    private readonly isFetchingFromSubdomain: boolean
    private readonly headers = useStateHeaders()
    private readonly requestEvent = useRequestEvent()

    constructor(options: ApiServiceOptions, model: ConstructorType<T> | null) {

        // capture the values into separate variables because `useConfig()` cannot be passed into
        // the `super()` call directly, because it is a composable and that would make the api url
        // builder not usable in places where the Nuxt instance is not available
        const config = useConfig()
        const privateConfig = usePrivateConfig()
        const apiUrl = options.relativeUrl ? '' : (import.meta.server ? privateConfig.apiUrlSsr || config.apiUrl : config.apiUrl) ?? ''
        const apiRoutePrefix = options.routePrefix ?? config.apiRoutePrefix ?? ''

        const finalUrl = options.url as string ?? joinURL(
            apiUrl,
            apiRoutePrefix,
            options.endpoint ?? ''
        )

        super({
            url: finalUrl,
        })

        this.isFetchingFromSubdomain = isFetchingFromSubdomain(finalUrl)
        this.model = model
    }

    // -----------------------------------------------------------------------------------------------------------------

    protected async fetch(options?: Partial<ApiServiceFetchOptions<T> & ServiceFetchBaseOptions<any>>) {
        const builderData = this.captureBuilderDataAndReset()
        const builtUrl = builderData.getBuiltUrl()
        const builtParams = builderData.getBuiltParams()

        const response = await apiFetch({
            model: this.model,
            url: builtUrl,
            usageOptions: options,
            event: this.requestEvent,
            isFetchingFromSubdomain: this.isFetchingFromSubdomain,
            headers: {
                ...this.headers.value as Record<string, string>,
                ...options?.headers,
            },
            params: builtParams,
            signal: options?.signal,
        })

        return response!
    }

}


