import validate from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_x5zfomuifdeortxhoem6l4pvce/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/simploshop/shared/core-theme/app/middleware/auth.global.ts";
import manifest_45route_45rule from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_x5zfomuifdeortxhoem6l4pvce/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  cart: () => import("/opt/simploshop/shared/core-theme/app/middleware/cart.ts")
}