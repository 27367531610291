export enum ProductVariationPropertyType {
    Select = 1,
    Radio = 2,
    Color = 3,
    TextInput = 4,
}

export enum ProductFilterType {
    SELECT = 'select',
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
    COLOR = 'color',
    PRICE_SLIDER = 'price_slider',
}

export enum ProductFilterTemplatePlaceholder {
    MIN_PRICE = 'MIN_PRICE',
    MAX_PRICE = 'MAX_PRICE',
    PAGE = 'PAGE',
    FROM = 'FROM',
    TO = 'TO',
    PER_PAGE = 'PER_PAGE',
}

export enum ProductRelationType {
    ALTERNATIVE = 0, // VARIANTS
    RELATED = 1, // RELATIVE
}

export enum ProductType {
    NORMAL = 0,
    PACKAGE = 1,
}
