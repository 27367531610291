import routerOptions0 from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_x5zfomuifdeortxhoem6l4pvce/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/simploshop/shared/core-theme/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}