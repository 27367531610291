<script lang="tsx">
import type { SlotsType } from 'vue'
import type { ScssBreakpoint } from '@core-types/scss'
import type { NuxtLink } from '#components'

/**
 * The allowed padding values for the container.
 * This includes the user-defined sizes, as well as some special ones like:
 * - `safe-only` - applies only the safe area padding
 * - `none` - applies no padding
 * - `default` - applies the default padding (default padding prop value)
 */
type PaddingValues<Sizes extends string> = Sizes | `${Sizes}-only` | 'safe-only' | 'none' | 'default'
type BreakpointPadding<Sizes extends string> = `${ScssBreakpoint}:${PaddingValues<Sizes>}` /* | `max-${ScssBreakpoint}:${PaddingValues<Sizes>}` // disabled for performance reasons */
export type Padding<Sizes extends string> = PaddingValues<Sizes> | BreakpointPadding<Sizes>
export type PaddingConfig<PaddingSizes extends string> = Padding<PaddingSizes> | Padding<PaddingSizes>[] | {
    top?: Padding<PaddingSizes> | Padding<PaddingSizes>[]
    right?: Padding<PaddingSizes> | Padding<PaddingSizes>[]
    bottom?: Padding<PaddingSizes> | Padding<PaddingSizes>[]
    left?: Padding<PaddingSizes> | Padding<PaddingSizes>[]
    vertical?: Padding<PaddingSizes> | Padding<PaddingSizes>[]
    horizontal?: Padding<PaddingSizes> | Padding<PaddingSizes>[]
}

export type BaseContainerContentProps<PaddingSizes extends string, ContentWidths extends string> = {
    /**
     * The HTML tag to use for the container.
     * @default 'div'
     */
    tag?: 'div' | 'section' | 'article' | 'nav' | 'ul' | 'button' | typeof NuxtLink

    padding?: PaddingConfig<PaddingSizes>

    /**
     * The max width of the container. (including padding)
     * @default 'normal'
     */
    width?: 'full' | 'normal' | ContentWidths
}

type BaseContainerContentSlots<PaddingSize extends string, ContentWidths extends string> = {
    default: {}
}

type ComponentOptions = {}

export function defineComponentBaseContainerContent<
    PaddingSizes extends Exclude<string, 'default' | 'none' | 'safe-only'>,
    ContentWidths extends string = 'normal',
>(options?: ComponentOverrideOptions<ComponentOptions, BaseContainerContentProps<PaddingSizes, ContentWidths>, BaseContainerContentSlots<PaddingSizes, ContentWidths>>) {
    return defineComponent(
        (props: BaseContainerContentProps<PaddingSizes, ContentWidths>, ctx) => {

            const tag = computed(() => {
                if (props.tag) return props.tag
                return 'div'
            })

            const defaultPadding = getDefaultPadding(options)

            /**
             * This function generates the padding classes for a specific side of the container or the container as a whole (if no
             * side is provided)
             *
             * @param padding The padding configuration for a specific side of the container or the container as a whole.
             * @param side The side of the container to generate padding classes for (optional, defaults to the whole container).
             */
            function generatePaddingClasses(padding: Padding<any> | Padding<any>[] | undefined, side?: 'top' | 'right' | 'bottom' | 'left') {
                const suffixes = generatePaddingSuffixes(padding ?? defaultPadding).map(suffix => suffix.replace('default', defaultPadding))

                const hasBasePadding = suffixes.some(suffix => !suffix.includes(':'))
                if (!hasBasePadding) suffixes.push(defaultPadding)

                if (!side) {
                    return suffixes.flatMap(suffix => ([
                        `cpt-${suffix}`,
                        `cpr-${suffix}`,
                        `cpb-${suffix}`,
                        `cpl-${suffix}`,
                    ]))
                }

                if (side === 'top') {
                    return suffixes.map(suffix => `cpt-${suffix}`)
                }

                if (side === 'bottom') {
                    return suffixes.map(suffix => `cpb-${suffix}`)
                }

                if (side === 'right') {
                    return suffixes.map(suffix => `cpr-${suffix}`)
                }

                if (side === 'left') {
                    return suffixes.map(suffix => `cpl-${suffix}`)
                }

                return []
            }


            const paddingClasses = computed(() => {
                const padding = props.padding

                if (typeof padding === 'string' || Array.isArray(padding)) {
                    return generatePaddingClasses(props.padding)
                }

                return [
                    ...generatePaddingClasses(padding?.top ?? padding?.vertical, 'top'),
                    ...generatePaddingClasses(padding?.right ?? padding?.horizontal, 'right'),
                    ...generatePaddingClasses(padding?.bottom ?? padding?.vertical, 'bottom'),
                    ...generatePaddingClasses(padding?.left ?? padding?.horizontal, 'left'),
                ]
            })

            return () => (
                <tag.value
                    class={['sim-cc',
                        ...paddingClasses.value,
                        {
                            [`cw-${props.width}`]: props.width,
                        },
                    ]}
                >
                    {renderSlot(ctx.slots.default, options?.slots?.default, {})}
                </tag.value>
            )
        },
        {
            props: {
                tag: {
                    type: [String, Object] as PropType<BaseContainerContentProps<PaddingSizes, ContentWidths>['tag']>,
                    default: options?.props?.tag?.default ?? 'div',
                    required: options?.props?.tag?.required ?? false,
                },

                padding: {
                    type: [String, Array, Object] as PropType<BaseContainerContentProps<PaddingSizes, ContentWidths>['padding']>,
                    default: getDefaultPadding(options),
                    required: options?.props?.padding?.required ?? false,
                },

                width: {
                    type: String as PropType<BaseContainerContentProps<PaddingSizes, ContentWidths>['width']>,
                    default: options?.props?.width?.default ?? 'normal',
                    required: options?.props?.width?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseContainerContentSlots<PaddingSizes, ContentWidths>>,
            emits: {},
        }
    )
}

/**
 * This function generates the suffixes for padding classes.
 * Some examples of complete padding classes are:
 * - `pr-large` - applies large padding (including the safe area) to the right side of the container.
 * - `pl-safe-only` - applies only the safe area padding to the left side of the container.
 * - `pb-sm:none` - applies no padding (neither safe nor regular) to the bottom side of the container after
 *                         the `sm` breakpoint.
 *
 * This function **DOES NOT generate** those complete classes, but rather the suffixes that are used to generate them,
 * like: `large`, `safe-only`, `sm:none` etc.
 * It returns an array of strings that still require the `${paddingSide}-` prefix to be a complete class.
 * @param padding The padding configuration for a specific side of the container or the container as a whole.
 */
function generatePaddingSuffixes(padding: Padding<any> | Padding<any>[]) {
    return Array.isArray(padding) ? padding : [padding]
}

function getDefaultPadding<
    PaddingSizes extends string,
    ContentWidths extends string = 'normal',
>(options: Parameters<typeof defineComponentBaseContainerContent<PaddingSizes, ContentWidths>>[0]) {
    return typeof options?.props?.padding?.default === 'string' ? options.props.padding.default : 'safe-only'
}

export default defineComponentBaseContainerContent()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseContainerContent.scss" as *;

</style>
